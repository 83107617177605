import {EquipmentData} from '@hconnect/common/types'
import {mockQueryReturn} from '@hconnect/common/utils'

import {mapShiftEvent} from '../../mappers'
import {ShiftEventDto} from '../../types/shiftHandover.types'
import {TIMEZONE} from '../scenarios/scenario.consts'

import {personas} from './persona'

const shiftWorkerJohnny = {
  id: personas.shiftWorkerJohnny.id,
  name: personas.shiftWorkerJohnny.name
}
const shiftLeader = {
  id: personas.shiftLeader.id,
  name: personas.shiftLeader.name
}
const shiftWorkerMarry = {
  id: personas.shiftWorkerMarry.id,
  name: personas.shiftWorkerMarry.name
}

const equipmentTH01: EquipmentData = {
  id: 'TH01',
  idType: 'technicalId',
  text: 'text for TH01'
}

const equipmentSapNumber: EquipmentData = {
  id: '000000000080231381',
  idType: 'sapNumber',
  text: 'text for sap equipment'
}

export const information: ShiftEventDto = {
  id: '1',
  title: 'initial event',
  description: 'the first event ever happening',
  eventType: 'information',
  category: 'process',
  equipment: equipmentTH01,
  processStage: '60',
  status: 'pending',
  priority: 5,
  createdBy: shiftWorkerJohnny,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T01:53:57.3604604+00:00',
  lastModified: '2020-10-29T09:53:57.3604604+00:00',
  isHighlighted: true,
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const malfunction: ShiftEventDto = {
  id: '2',
  title: 'Est consequuntur aliquid quibusdam.',
  description: 'Quidem qui hic consequatur voluptatem delectus maiores et beatae tempore placeat.',
  eventType: 'malfunction',
  category: 'mechanical',
  equipment: equipmentTH01,
  processStage: '60',
  status: 'done',
  priority: 5,
  createdBy: shiftWorkerMarry,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T10:53:57.3604604+00:00',
  lastModified: '2020-10-29T11:00:57.3604604+00:00',
  isHighlighted: true,
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const parameterChange: ShiftEventDto = {
  id: '3',
  title: 'Rerum nostrum eum laborum dolor et ut delectus incidunt laboriosam eaque voluptate nihil',
  description:
    'Magnam eaque facilis deserunt dolor et ut delectus incidunt laboriosam eaque voluptate nihil laboriosam nisi illo dolor sit maiores.',
  eventType: 'parameterChange',
  category: 'quality',
  equipment: equipmentTH01,
  processStage: '50',
  status: 'pending',
  priority: 4,
  createdBy: shiftWorkerMarry,
  modifiedBy: shiftWorkerMarry.name,
  created: '2020-10-29T11:35:37.8469466+00:00',
  lastModified: '2020-10-29T11:35:37.8469466+00:00',
  isHighlighted: false,
  parameterChange: {
    needsApproval: true,
    approvers: [personas.shiftLeader]
  },
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const healthAndSafety: ShiftEventDto = {
  id: '4',
  title: 'Fugit quaerat distinctio.',
  description:
    'Ut magni hic soluta dignissimos temporibus quam aspernatur ex consequatur consequuntur natus iure ipsam eos et.',
  eventType: 'healthAndSafety',
  category: 'electrical',
  equipment: equipmentTH01,
  processStage: '30',
  status: 'ongoing',
  priority: 4,
  createdBy: shiftWorkerMarry,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T12:00:14.7631135+00:00',
  lastModified: '2020-10-29T12:30:00.7631135+00:00',
  isHighlighted: false,
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const task: ShiftEventDto = {
  id: '5',
  title: 'check kiln',
  description:
    'Dignissimos temporibus quam aspernatur ex consequatur consequuntur natus iure ipsam',
  eventType: 'task',
  category: 'other',
  equipment: equipmentTH01,
  processStage: '40',
  status: 'pending',
  createdBy: shiftLeader,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T13:30:22.8886054+00:00',
  lastModified: '2020-10-29T15:04:33.8886054+00:00',
  dueDate: '2020-10-30T23:59:59.8886054+00:00',
  assignees: [shiftLeader],
  isHighlighted: false
}

export const environment: ShiftEventDto = {
  id: '6',
  title: 'Oil Leak',
  description:
    'Dignissimos temporibus quam aspernatur ex consequatur consequuntur natus iure ipsam',
  eventType: 'environment',
  category: 'other',
  equipment: equipmentTH01,
  processStage: '60',
  status: 'pending',
  priority: 4,
  createdBy: shiftLeader,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T13:30:22.8886054+00:00',
  lastModified: '2020-10-29T15:04:33.8886054+00:00',
  isHighlighted: false,
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const idea: ShiftEventDto = {
  id: '8',
  title: 'An Idea event',
  description: 'An idea to improve the process',
  eventType: 'idea',
  category: 'process',
  equipment: equipmentTH01,
  processStage: '60',
  status: 'pending',
  priority: 5,
  createdBy: shiftWorkerJohnny,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T01:53:57.3604604+00:00',
  lastModified: '2020-10-29T09:53:57.3604604+00:00',
  isHighlighted: false,
  inforWorkOrder: {
    requestId: 'id-id'
  }
}

export const taskRecurringDaily: ShiftEventDto = {
  id: '7',
  title: 'Take sample from the mill',
  description:
    'For the cement strength prediction, the lab needs a fresh sample of the milled limestone',
  eventType: 'task',
  category: 'quality',
  equipment: equipmentSapNumber,
  processStage: '30',
  status: 'pending',
  createdBy: shiftLeader,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T13:50:19.8886054+00:00',
  lastModified: '2020-10-29T15:04:33.8886054+00:00',
  dueDate: '2020-10-30T23:59:59.8886054+00:00',
  assignees: [personas.shiftLeader],
  repetitionInfo: {
    repetition: 'daily',
    enabled: true
  },
  isHighlighted: false
}

export const taskRecurringMonthly: ShiftEventDto = {
  id: '9',
  title: 'Clean Dust filter',
  description: 'To make sure the filter is working as expected, it has to get cleaned once a month',
  eventType: 'task',
  category: 'quality',
  equipment: equipmentSapNumber,
  processStage: '30',
  status: 'pending',
  createdBy: shiftLeader,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T13:50:19.8886054+00:00',
  lastModified: '2020-10-29T15:04:33.8886054+00:00',
  dueDate: '2020-10-30T23:59:59.8886054+00:00',
  assignees: [personas.shiftLeader],
  repetitionInfo: {
    repetition: 'monthly',
    enabled: true
  },
  isHighlighted: false
}

export const taskRecurringWithDisabledTaskSchedule: ShiftEventDto = {
  id: '13',
  title: 'Disabled task schedule',
  description: 'Ensure disabled series cannot be edited',
  eventType: 'task',
  category: 'quality',
  equipment: equipmentSapNumber,
  processStage: '30',
  status: 'done',
  createdBy: shiftLeader,
  modifiedBy: shiftLeader.name,
  created: '2020-10-29T13:50:19.8886054+00:00',
  lastModified: '2020-10-29T15:04:33.8886054+00:00',
  dueDate: '2020-10-30T23:59:59.8886054+00:00',
  assignees: [personas.shiftLeader],
  repetitionInfo: {
    repetition: 'daily',
    enabled: false
  },
  isHighlighted: true
}

export const dailyRecurringTasksList: ShiftEventDto[] = [
  {...taskRecurringDaily, id: '5000'},
  {
    ...taskRecurringDaily,
    title: 'RE1:Take sample from the mill',
    id: '5001',
    dueDate: '2020-10-31T23:59:59.8886054+00:00'
  },
  {
    ...taskRecurringDaily,
    title: 'RE2:Take sample from the mill',
    id: '5002',
    dueDate: '2020-11-01T23:59:59.8886054+00:00'
  },
  {
    ...taskRecurringDaily,
    title: 'RE3:Take sample from the mill',
    id: '5003',
    dueDate: '2020-11-02T23:59:59.8886054+00:00'
  },
  {
    ...taskRecurringWithDisabledTaskSchedule
  }
]

export const monthlyRecurringTasksList: ShiftEventDto[] = [
  {...taskRecurringMonthly, id: '5100'},
  {
    ...taskRecurringMonthly,
    title: 'RE1:Clean Dust filter',
    id: '5101',
    dueDate: '2020-11-30T23:59:59.8886054+00:00'
  },
  {
    ...taskRecurringMonthly,
    title: 'RE2:Clean Dust filter',
    id: '5102',
    dueDate: '2020-12-30T23:59:59.8886054+00:00'
  },
  {
    ...taskRecurringMonthly,
    title: 'RE3:Clean Dust filter',
    id: '5103',
    dueDate: '2021-01-30T23:59:59.8886054+00:00'
  }
]

export const eventData = mockQueryReturn(mapShiftEvent(task, TIMEZONE))
// used in unit tests
export const eventWithRepetitionData = mockQueryReturn(
  mapShiftEvent(dailyRecurringTasksList[0], TIMEZONE)
)
